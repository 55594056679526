/* poppins font */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/* inter font */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

/* kanit font */
@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Gochi+Hand&display=swap");

/* montserrat font */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');



/* root */

:root {

    --blue_200: #16ebc3;
    --blue_300: #1042f3;
    --black: #000000;
    --white: #ffffff;
    --gray_200: #1c1c1c;


    --montserrat_font: "Montserrat", sans-serif;
    --poppins_font: "Poppins", sans-serif;
    --kanit_font: "Kanit", sans-serif;

    --container-width-main: 87%;
    --container-width-sm-main: 90%;
    --container-width-inner: 70%;

    /* --main-title-font: 4.2vh; */
    --main-title-font: 4vh;
    --card-title-font: 2.5vh;
    --card-desc-font: 1.5vh;
    --modal-text: 1.8vh;
}



* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    color: var(--white);
    font-family: var(--montserrat_font);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin-bottom: 0px;
}



